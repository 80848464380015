import React from 'react'

export default class Refresh extends React.Component {
  static defaultProps = {
    every: 1000
  }

  state = {
    lastUpdate: null
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ lastUpdate: Date.now() }),
      this.props.every
    )
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return this.props.children(this.props, this.state.lastUpdate)
  }
}
