import React from 'react'

import {
  Button,
  ButtonGroup,
  FormGroup,
  InputGroup,
  Tooltip
} from '@blueprintjs/core'

export default class Secret extends React.Component {
  state = {
    visible: false
  }

  static defaultProps = {
    showIcon: 'eye-open',
    hideIcon: 'eye-off',
    large: true,
    inline: false,
    textLike: false
  }

  copyToClipboard = e => {
    this.input.select()
    document.execCommand('copy')
  }

  render() {
    const {textLike, showIcon, hideIcon, ...props} = this.props
    return (
      <FormGroup
        label={this.props.name}
        inline={this.props.inline}
        style={
            textLike
              ? { margin: 0 }
              : undefined
          }
      >
        <InputGroup
          style={
            textLike
              ? { border: 'none', boxShadow: 'none', margin: 0, backgroundColor: 'transparent' }
              : undefined
          }
          readOnly
          inputRef={x => (this.input = x)}
          {...props}
          type={this.state.visible ? 'text' : 'password'}
          rightElement={
            <ButtonGroup>
              {this.state.visible &&
                !this.props.textLike &&
                document.queryCommandSupported('copy') && (
                  <Tooltip content={`Copy`}>
                    <Button
                      onClick={this.copyToClipboard}
                      icon={'clipboard'}
                      minimal={true}
                    />
                  </Tooltip>
                )}
              <Tooltip content={`${this.state.visible ? 'Hide' : 'Show'}`}>
                <Button
                  icon={
                    this.state.visible
                      ? hideIcon
                      : showIcon
                  }
                  minimal={true}
                  onClick={() =>
                    this.setState({ visible: !this.state.visible })
                  }
                />
              </Tooltip>
            </ButtonGroup>
          }
        />
      </FormGroup>
    )
  }
}
